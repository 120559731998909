

.noUi-target.noUi-horizontal {
  height: 5px;
  border: 0;
  background-color: #d3d3d3;
  box-shadow: none;
  margin-left: 8px;
  .noUi-handle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    top: -5px !important;
    right: -8px !important;
    background: #000;
    box-shadow: none;
    border: 0;
    &::before {
      display: none;
    }
    &::after {
      display: none;
      content: '';
      position: absolute;
      bottom: calc(100% + 8px);
      left: 50%;
      transform: translateX(-50%);
      background: #000;
      color: #fff;
      border-radius: 3px;
      min-width: 4em;
      font-weight: 500;
      padding: .4em;
      text-align: center;
      box-shadow: 0 4px 8px rgba(#000, .2);
   }
  }
  .noUi-connect {
    background: #000;
  }

  // .noUi-marker-horizontal.noUi-marker {
  //     display: none;
  // }

  .noUi-tooltip {
    bottom: auto;
    top: 120%;
    border: 0;
    font-size: 13px;
    &::before {
      content: '$';
    }
  }
}
